import { createSlice } from "@reduxjs/toolkit";
import { addData, deleteMultipleCommitments, getAllData, getAllLists, getOrganizationsEmission } from "./commitmentsAction";

const commitmentsSlice = createSlice({
    name: "commitments",
    initialState: {
        allData: [],
        addData: {},
        lists: [],
        emissionsData: {},
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllData.pending, (state) => {
                state.loading = true;
                state.success = false;
                state.error = null;
            }).addCase(getAllData.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.allData = action.payload.commitments;
            }).addCase(getAllData.rejected, (state) => {
                state.loading = false;
                state.success = false;
                state.error = "Failed to get all data.";
            }).addCase(addData.pending, (state) => {
                state.loading = true;
                state.success = false;
                state.error = null;
            }).addCase(addData.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.addData = action.payload;
            }).addCase(addData.rejected, (state) => {
                state.loading = false;
                state.success = false;
                state.error = "Failed to get all data.";
            }).addCase(getAllLists.pending, (state) => {
                state.loading = true;
                state.success = false;
                state.error = null;
            }).addCase(getAllLists.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.lists = action.payload.data;
            }).addCase(getAllLists.rejected, (state) => {
                state.loading = false;
                state.success = false;
                state.error = "Failed to get all data.";
            }).addCase(getOrganizationsEmission.pending, (state) => {
                state.loading = true;
                state.success = false;
                state.error = null;
            }).addCase(getOrganizationsEmission.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.emissionsData = action.payload;
            }).addCase(getOrganizationsEmission.rejected, (state) => {
                state.loading = false;
                state.success = false;
                state.error = "Failed to get all data.";
            }).addCase(deleteMultipleCommitments.pending, (state) => {
                state.loading = true;
                state.success = false;
                state.error = null;
            }).addCase(deleteMultipleCommitments.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
            }).addCase(deleteMultipleCommitments.rejected, (state) => {
                state.loading = false;
                state.success = false;
                state.error = "Failed to delete data.";
            });;
    },
});

export default commitmentsSlice.reducer;
