/* eslint-disable no-unused-vars */
import {
  EvFossilFuel,
  fuelEmissionsFactors,
  iceProdEmissionFactors,
  insMaintEmissionFactors,
  rentalEmissionsPerEuro,
  wasteEmissionFactors,
} from './emissionFactors'

// Get emission factors depending on the fossil fuel electricity selected
export const getElectricEmissionFactor = (fossilFuelElec) => {
  switch (Number(fossilFuelElec)) {
    case 0:
      return EvFossilFuel.EV0
    case 20:
      return EvFossilFuel.EV20
    case 40:
      return EvFossilFuel.EV40
    case 60:
      return EvFossilFuel.EV60
    case 80:
      return EvFossilFuel.EV80
    case 100:
      return EvFossilFuel.EV100
    default:
      return EvFossilFuel.EV0 // returning null will raise errors
  }
}

// calculate CO, VOC, NOx
export const calculateCVN = (
  isElectric,
  hasMileage,
  emissionFactor,
  numVehicles,
  annualMileage,
  annualFuel,
  preEuroValue,
) => {
  let cVN = null
  let distance = annualMileage

  // if no vehicles, return zero
  if (numVehicles === undefined || numVehicles <= 0) return 0

  if (!hasMileage) {
    if (numVehicles !== undefined || annualFuel !== undefined) {
      distance = 1
    }
  }

  if (isElectric) {
    cVN = (1 - emissionFactor) * (distance / 1000) * preEuroValue
  } else {
    cVN = emissionFactor * (distance / 1000)
  }

  return Number(cVN) || 0
}

// calculate SOx, PM10
export const calculateSP = (
  isElectric,
  hasMileage,
  emissionFactor,
  numVehicles,
  annualMileage,
  annualFuel,
  preEuroValue,
  sulphurLevel,
) => {
  let sP = null
  let distance = annualMileage

  if (!hasMileage) {
    if (numVehicles !== undefined || annualFuel !== undefined) {
      distance = 1
    } else return 0
  }

  if (isElectric) {
    sP = (1 - emissionFactor) * (sulphurLevel / 15) * (distance / 1000) * preEuroValue
  } else {
    sP = emissionFactor * (sulphurLevel / 15) * (distance / 1000)
  }

  return Number(sP) || 0
}

// calculate CO2
export const calculateCO2 = (
  isElectric,
  hasFuel,
  annualFuel,
  numVehicles,
  annualMileage,
  carbonPerLitre,
  emissionFactor,
) => {
  let annualFuelConst = annualFuel
  let carbon = null

  if (!hasFuel) {
    if (numVehicles !== undefined || annualMileage !== undefined) {
      annualFuelConst = 0
    }
  }

  if (isElectric) {
    carbon = (1 - emissionFactor) * annualFuelConst * fuelEmissionsFactors.gasoline.scope1 // prev: petrolCO2perLtr
  } else {
    carbon = annualFuelConst * carbonPerLitre
  }

  return Number(carbon) || 0
}

// Calculate Scoped CO2 Emissions
export const calculateScopedCO2 = ({
  vehicleCategory = 'default',
  ownedVehicles,
  ownRecentNum,
  rentedVehicles,
  fuelQuantity,
  fuelType,
  maintenanceAmount,
  insuranceAmount,
  rentalAmount,
  kWHConsumed,
  countryData,
  euroRate,
  lubricantsWeight,
  tyresWeight,
  batteriesWeight,
  passSUVDieselMileage,
  passSUVDieselNum,
  customWasteValues = true
}) => {
  // Check if the emissions calculations are unscoped, if not, use the total emission factors

  // combine the values for rental and owned vehicles, as calculations for these are the same (other than waste emissions)
  // console.log('ownedVehicles:', ownedVehicles)
  // console.log('rentedVehicles:', rentedVehicles)
  // console.log('totalVehicles:', Number(ownedVehicles || 0) + Number(rentedVehicles || 0))
  const totalVehicles = Number(ownedVehicles || 0) + Number(rentedVehicles || 0)
  if (totalVehicles === 0) {
    return {
      scope1: 0,
      scope2: 0,
      scope3: 0,
    }
  }

  // A. =================================== OWNED ICEs ============================================
  // Scope 1
  // Fuel emissions: fuelQuantity x fuelEmissionsFactors[fuelType].scope1
  const ownedICEscope1FuelEmissions =
    (fuelType && Number(fuelQuantity) * fuelEmissionsFactors[fuelType].scope1) || 0

  // console.log('fuelQuantity', fuelQuantity)
  // console.log('Scope1 emission factor:', fuelEmissionsFactors[fuelType]?.scope1)
  // console.log('Owned ICEs Scope 1 CO2:', ownedICEscope1FuelEmissions)
  // Scope 2 => N/A
  //
  // Scope 3
  // Production emissions: ownRecentNum x iceProdEmissionFactors[vehicleCategory].scope3
  // Fuel emissions: fuelQuantity x fuelEmissionsFactors[fuelType].scope3 - Fuel emsissions are scope 1
  // Maintenance Emissions: maintenanceAmount x insMaintEmissionFactors.maintenance
  // Insurance Emissions: insuranceAmount x insMaintEmissionFactors.insurance
  const ownedICEscope3ProdEmissions =
    Number(ownRecentNum) * Number(iceProdEmissionFactors[vehicleCategory].scope3) || 0
  const ownedICEscope3FuelEmissions =
    (fuelType && Number(fuelQuantity) * Number(fuelEmissionsFactors[fuelType].scope3)) || 0
  const ownedICEScope3maintEmissions =
    (maintenanceAmount &&
      (Number(maintenanceAmount) / Number(euroRate) || 0) *
        Number(insMaintEmissionFactors.maintenance)) ||
    0
  const ownedICEscope3InsEmissions =
    (insuranceAmount &&
      (Number(insuranceAmount) / Number(euroRate) || 0) *
        Number(insMaintEmissionFactors.insurance)) ||
    0

  // B. =================================== OWNED BEVs ============================================
  // Scope 1 => N/A
  //
  // Scope 2
  // Electricity emissions: kWHConsumed x countryData?.scope2
  const ownedBEVsScope2ElecEmissions =
    (ownedVehicles && Number(kWHConsumed) * Number(countryData?.scope2)) || 0

  // Scope 3
  // Production emissions: ownRecentNum x iceProdEmissionFactors[vehicleCategory].scope3
  // Electricity emissions: kWHConsumed x countryData?.scope3
  // Maintenance Emissions: maintenanceAmount x insMaintEmissionFactors.maintenance
  // Insurance Emissions: insuranceAmount x insMaintEmissionFactors.insurance
  const ownedBEVscope3ProdEmissions =
    (ownedVehicles &&
      Number(ownRecentNum) * Number(iceProdEmissionFactors[vehicleCategory].scope3)) ||
    0 // needs to be changed to BEV emissions factors
  const ownedBEVscope3ElecEmissions =
    (ownedVehicles && Number(kWHConsumed) * Number(countryData?.scope3)) || 0
  const ownedBEVscope3maintEmissions =
    (maintenanceAmount &&
      (Number(maintenanceAmount) / Number(euroRate) || 0) *
        Number(insMaintEmissionFactors.maintenance)) ||
    0
  const ownedBEVscope3InsEmissions =
    (insuranceAmount &&
      (Number(insuranceAmount) / Number(euroRate) || 0) *
        Number(insMaintEmissionFactors.insurance)) ||
    0

  // C. =================================== OWNED HEVs ============================================
  // Scope 1
  // Fuel emissions: fuelQuantity x fuelEmissionsFactors[fuelType].scope1
  const ownedHEVsScope1FuelEmissions =
    (fuelType && Number(fuelQuantity) * Number(fuelEmissionsFactors[fuelType].scope1)) || 0

  // Scope 2
  // Electricity emissions: kWHConsumed x countryData?.scope2
  const ownedHEVsScope2ElecEmissions = Number(kWHConsumed) * Number(countryData?.scope2) || 0

  // Scope 3
  // Production emissions: ownRecentNum x iceProdEmissionFactors[vehicleCategory].scope3
  // Fuel emissions: fuelQuantity x fuelEmissionsFactors[fuelType].scope3
  // Electricity emissions: kWHConsumed x countryData?.scope3
  // Maintenance Emissions: maintenanceAmount x insMaintEmissionFactors.maintenance
  // Insurance Emissions: insuranceAmount x insMaintEmissionFactors.insurance
  const ownedHEVsScope3ProdEmissions =
    (ownedVehicles &&
      Number(ownRecentNum) * Number(iceProdEmissionFactors[vehicleCategory].scope3)) ||
    0
  const ownedHEVsScope3FuelEmissions =
    (fuelType && Number(fuelQuantity) * Number(fuelEmissionsFactors[fuelType].scope3)) || 0
  const ownedHEVscope3ElecEmissions = Number(kWHConsumed) * Number(countryData?.scope3) || 0
  const ownedHEVsScope3maintEmissions =
    (maintenanceAmount &&
      (Number(maintenanceAmount) / Number(euroRate) || 0) *
        Number(insMaintEmissionFactors.maintenance)) ||
    0
  const ownedHEVsScope3InsEmissions =
    (insuranceAmount &&
      (Number(insuranceAmount) / Number(euroRate) || 0) *
        Number(insMaintEmissionFactors.insurance)) ||
    0

  // D. =================================== RENTED ICEs ============================================
  // Scope 1
  // Fuel emissions: fuelQuantity x fuelEmissionsFactors[fuelType].scope1
  const rentedICEsScope1FuelEmissions =
    (fuelType && Number(fuelQuantity) * Number(fuelEmissionsFactors[fuelType].scope1)) || 0

  // Scope 2 -> N/A
  //
  // Scope 3
  // Fuel emissions: fuelQuantity x fuelEmissionsFactors[fuelType].scope3
  // Rental service emission: (rentalAmount x euroRate) x rentalEmissionsPerEuro
  const rentedICEsScope3FuelEmissions =
    (fuelType && Number(fuelQuantity) * Number(fuelEmissionsFactors[fuelType].scope3)) || 0
  const rentedICEsScope3RentalSvcEmissions =
    (rentalAmount &&
      (Number(rentalAmount) / Number(euroRate) || 0) * Number(rentalEmissionsPerEuro)) ||
    0
  // (rentalAmount && Number(rentalAmount) * Number(euroRate) * Number(rentalEmissionsPerEuro)) || 0 // old implementation (wasn't dividing by euro rate)

  // E. =================================== RENTED BEVs ============================================
  // Scope 1 => N/A
  //
  // Scope 2
  // Electricity emissions: kWHConsumed x countryData?.scope2
  const rentedBEVsScope2ElecEmissions = Number(kWHConsumed) * Number(countryData?.scope2) || 0

  // Scope 3
  // Electricity emissions: kWHConsumed x countryData?.scope3
  // Rental service emission: (rentalAmount x euroRate) x rentalEmissionsPerEuro
  const rentedBEVsScope3ElecEmissions = Number(kWHConsumed) * Number(countryData?.scope3) || 0
  const rentedBEVsScope3RentalSvcEmissions =
    (rentalAmount &&
      (Number(rentalAmount) / Number(euroRate) || 0) * Number(rentalEmissionsPerEuro)) ||
    0

  // F. =================================== RENTED HEVs ============================================
  // Scope 1
  // Fuel emissions: fuelQuantity x fuelEmissionsFactors[fuelType].scope1
  const rentedHEVsScope1FuelEmissions =
    (fuelType && Number(fuelQuantity) * Number(fuelEmissionsFactors[fuelType].scope1)) || 0

  // Scope 2
  // Electricity emissions: kWHConsumed x countryData?.scope2
  const rentedHEVsScope2ElecEmissions = Number(kWHConsumed) * Number(countryData?.scope2) || 0

  // Scope 3
  // Fuel emissions: fuelQuantity x fuelEmissionsFactors[fuelType].scope3
  // Electricity emissions: kWHConsumed x countryData?.scope3
  // Rental service emission: (rentalAmount x euroRate) x rentalEmissionsPerEuro
  const rentedHEVsScope3FuelEmissions =
    (fuelType && Number(fuelQuantity) * Number(fuelEmissionsFactors[fuelType].scope3)) || 0
  const rentedHEVsScope3ElecEmissions = Number(kWHConsumed) * Number(countryData?.scope3) || 0
  const rentedHEVsScope3RentalSvcEmissions =
    (rentalAmount &&
      (Number(rentalAmount) / Number(euroRate) || 0) * Number(rentalEmissionsPerEuro)) ||
    0

  // G. =================================== WASTE ============================================
  // console.log('custom waste values:', customWasteValues)
  // the lubricant, tyres and batteries waste are all scope 1 emissions
  const lubricantWasteEmissions = customWasteValues
    ? (lubricantsWeight && Number(lubricantsWeight) * Number(wasteEmissionFactors.lubricants)) || 0
    : Number(wasteEmissionFactors.lubricants) * 0.0098 * Number(passSUVDieselMileage) || 0
  const tyresWasteEmissions = customWasteValues
    ? (tyresWeight && Number(tyresWeight) * Number(wasteEmissionFactors.tyres)) || 0
    : Number(wasteEmissionFactors.tyres) * Number(passSUVDieselMileage) || 0
  const batteriesWasteEmissions = customWasteValues
    ? (batteriesWeight && Number(batteriesWeight) * Number(wasteEmissionFactors.batteries)) || 0
    : Number(wasteEmissionFactors.batteries) * (Number(passSUVDieselNum) / 2) || 0

  // Consolidate emissions
  const consolidatedEmissions = {
    scope1: (
      Number(ownedICEscope1FuelEmissions) ||
      0 + Number(ownedHEVsScope1FuelEmissions) ||
      0 + Number(rentedICEsScope1FuelEmissions) ||
      0 + Number(rentedHEVsScope1FuelEmissions) ||
      0
    ).toFixed(2),
    scope2: (
      Number(ownedBEVsScope2ElecEmissions) ||
      0 + Number(ownedHEVsScope2ElecEmissions) ||
      0 + Number(rentedBEVsScope2ElecEmissions) ||
      0 + Number(rentedHEVsScope2ElecEmissions) ||
      0
    ).toFixed(2),
    scope3: (
      Number(ownedICEscope3ProdEmissions) ||
      0 + Number(ownedICEscope3FuelEmissions) ||
      0 + Number(ownedBEVscope3ProdEmissions) ||
      0 + Number(ownedBEVscope3ElecEmissions) ||
      0 + Number(ownedHEVsScope3ProdEmissions) ||
      0 + Number(ownedHEVsScope3FuelEmissions) ||
      0 + Number(ownedHEVscope3ElecEmissions) ||
      0 + Number(rentedICEsScope3FuelEmissions) ||
      0 + Number(rentedBEVsScope3ElecEmissions) ||
      0 + Number(rentedHEVsScope3FuelEmissions) ||
      0 + Number(rentedHEVsScope3ElecEmissions) ||
      0 + Number(lubricantWasteEmissions) ||
      0 + Number(tyresWasteEmissions) ||
      0 + Number(batteriesWasteEmissions) ||
      0
    ).toFixed(2),
    fleetWide: (
      Number(ownedICEScope3maintEmissions) ||
      0 + Number(ownedBEVscope3maintEmissions) ||
      0 + Number(ownedHEVsScope3maintEmissions) ||
      0 + Number(ownedICEscope3InsEmissions) ||
      0 + Number(ownedBEVscope3InsEmissions) ||
      0 + Number(ownedHEVsScope3InsEmissions) ||
      0 + Number(rentedICEsScope3RentalSvcEmissions) ||
      0 + Number(rentedBEVsScope3RentalSvcEmissions) ||
      0 + Number(rentedHEVsScope3RentalSvcEmissions) ||
      0
    ).toFixed(2),
    waste: {
      lubes: (Number(lubricantWasteEmissions) || 0).toFixed(2),
      tyres: (Number(tyresWasteEmissions) || 0).toFixed(2),
      batteries: (Number(batteriesWasteEmissions) || 0).toFixed(2),
    },
  }

  // console.log('Consolidated Emissions:', consolidatedEmissions)

  return consolidatedEmissions
}

// Calculate cargo transport emisssions
export const calculateCargoTransportEmissions = ({ cargoFuel, cargoDistance, cargoWeight }) => {
  /*
  Calculation:
  TotalCO2 = Fuel Used x Diesel Emissions Factor
  CO2PerKm = TotalCO2 / Total Distance
  CO2/KM/MetricTon = TotalMetricTons / CO2perKm
  */

  const TotalCO2 = Number(cargoFuel) * fuelEmissionsFactors.diesel.scope3 || 0 // prev: dieselCO2perLtr
  const CO2PerKm = TotalCO2 / Number(cargoDistance) || 0
  const CO2PerMetricTonPerKm = Number(cargoWeight) / CO2PerKm || 0

  return CO2PerMetricTonPerKm.toFixed(2)
}
