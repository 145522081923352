export const fuelTypes = Object.freeze({
  gasoline: 'gasoline',
  diesel: 'diesel',
})

// Emmisions per litre of fuel used (in MT) -- updated to HCC+ factors

// Note: Update the Main CFT to use a combination of scope 1 and 3, and limit the commitments to only scope 1 for now.
export const fuelEmissionsFactors = {
  gasoline: {
    scope1: 2.162 / 1000,  //  prev was 2.31
    scope2: 0,
    scope3: 0.613 / 1000,
    totalEf: (2.162 + 0 + 0.613)
  },
  diesel: {
    scope1: 2.558 / 1000,  // prev was 2.69
    scope2: 0,
    scope3: 0.610 / 1000,  //  prev was  0.69
    totalEf: (2.558 + 0 + 0.610)
  },
  default: {
    scope1: 0,
    scope2: 0,
    scope3: 0,
    totalEf: 0
  },
}

// export these two individually to keep current calculations intact
export const petrolCO2perLtr = fuelEmissionsFactors.gasoline.totalEf
export const dieselCO2perLtr = fuelEmissionsFactors.diesel.totalEf

// Emissions for the production of ICE vehicles (in MT)
export const iceProdEmissionFactors = Object.freeze({
  passengerVehicles: {
    scope1: 0,
    scope2: 0,
    scope3: 7700 / 1000,
  },
  passengerSmallTrucksSUVs: {
    scope1: 0,
    scope2: 0,
    scope3: 11550 / 1000,
  },
  ldvs: {
    scope1: 0,
    scope2: 0,
    scope3: 19800 / 1000,
  },
  mdvs: {
    scope1: 0,
    scope2: 0,
    scope3: 53625 / 1000,
  },
  hdvs: {
    scope1: 0,
    scope2: 0,
    scope3: 192500 / 1000,
  },
  motorcycles: {
    scope1: 0,
    scope2: 0,
    scope3: 605 / 1000,
  },
  generators: {
    scope1: 0,
    scope2: 0,
    scope3: 8800 / 1000,
  },
  default: {
    scope1: 0,
    scope2: 0,
    scope3: 0,
  },
})
// console.log(iceVehicleCategories);

// Emissions for insurance and maintenance of vehicles per Euro spent
export const insMaintEmissionFactors = Object.freeze({
  insurance: 110 / 1000,
  maintenance: 215 / 1000,
})

// Emission factors for waste
export const wasteEmissionFactors = Object.freeze({
  lubricants: 1450 / 1000,
  tyres: 18326 / 1000,
  batteries: 137500 / 1000,
})

// Emissions for hired / rental vehicles (scope 3 emission)
export const rentalEmissionsPerEuro = 2.03614 / 1000 // 2,036.14 kgCO2e/k€

export const PassengerCarsGasoline = Object.freeze({
  preEuro: {
    carbonMonoxide: 8.5 / 1000,
    volatileOrganic: 0.93 / 1000,
    nitrousOxide: 1.4 / 1000,
    sulphurOxide: 0.025 / 1000,
    particulateMatter: 0.02 / 1000,
  },
  euro1: {
    carbonMonoxide: 8.5 / 1000,
    volatileOrganic: 0.93 / 1000,
    nitrousOxide: 1.4 / 1000,
    sulphurOxide: 0.025 / 1000,
    particulateMatter: 0.02 / 1000,
  },
  euro2: {
    carbonMonoxide: 4.34 / 1000,
    volatileOrganic: 0.46 / 1000,
    nitrousOxide: 0.76 / 1000,
    sulphurOxide: 0.02 / 1000,
    particulateMatter: 0.01 / 1000,
  },
  euro3: {
    carbonMonoxide: 3.7 / 1000,
    volatileOrganic: 0.27 / 1000,
    nitrousOxide: 0.64 / 1000,
    sulphurOxide: 0.02 / 1000,
    particulateMatter: 0.006 / 1000,
  },
  euro4: {
    carbonMonoxide: 2.63 / 1000,
    volatileOrganic: 0.14 / 1000,
    nitrousOxide: 0.12 / 1000,
    sulphurOxide: 0.009 / 1000,
    particulateMatter: 0.006 / 1000,
  },
  euro5: {
    carbonMonoxide: 1.8 / 1000,
    volatileOrganic: 0.11 / 1000,
    nitrousOxide: 0.08 / 1000,
    sulphurOxide: 0.0056 / 1000,
    particulateMatter: 0.006 / 1000,
  },
  euro6: {
    carbonMonoxide: 1.78 / 1000,
    volatileOrganic: 0.11 / 1000,
    nitrousOxide: 0.07 / 1000,
    sulphurOxide: 0.003 / 1000,
    particulateMatter: 0.006 / 1000,
  },
})

export const PassengerCarsDiesel = Object.freeze({
  euro3: {
    carbonMonoxide: 0.19 / 1000,
    volatileOrganic: 0.1 / 1000,
    nitrousOxide: 0.57 / 1000,
    sulphurOxide: 0.03 / 1000,
    particulateMatter: 0.08 / 1000,
  },
  euro4: {
    carbonMonoxide: 0.19 / 1000,
    volatileOrganic: 0.1 / 1000,
    nitrousOxide: 1.64 / 1000,
    sulphurOxide: 0.01 / 1000,
    particulateMatter: 0.006 / 1000,
  },
  euro5: {
    carbonMonoxide: 0.24 / 1000,
    volatileOrganic: 0.02 / 1000,
    nitrousOxide: 0.28 / 1000,
    sulphurOxide: 0.003 / 1000,
    particulateMatter: 0.006 / 1000,
  },
  euro6: {
    carbonMonoxide: 1.7 / 1000,
    volatileOrganic: 0.04 / 1000,
    nitrousOxide: 0.14 / 1000,
    sulphurOxide: 0.001 / 1000,
    particulateMatter: 0.006 / 1000,
  },
})

export const PassengerSUVGasoline = Object.freeze({
  preEuro: {
    carbonMonoxide: 19.51 / 1000,
    volatileOrganic: 1.73 / 1000,
    nitrousOxide: 2.76 / 1000,
    sulphurOxide: 0.04 / 1000,
    particulateMatter: 0.04 / 1000,
  },
  euro1: {
    carbonMonoxide: 19.16 / 1000,
    volatileOrganic: 1.68 / 1000,
    nitrousOxide: 2.79 / 1000,
    sulphurOxide: 0.02 / 1000,
    particulateMatter: 0.02 / 1000,
  },
  euro2: {
    carbonMonoxide: 8.2 / 1000,
    volatileOrganic: 0.98 / 1000,
    nitrousOxide: 1.44 / 1000,
    sulphurOxide: 0.02 / 1000,
    particulateMatter: 0.02 / 1000,
  },
  euro3: {
    carbonMonoxide: 7.17 / 1000,
    volatileOrganic: 0.78 / 1000,
    nitrousOxide: 1.16 / 1000,
    sulphurOxide: 0.02 / 1000,
    particulateMatter: 0.01 / 1000,
  },
  euro4: {
    carbonMonoxide: 4.45 / 1000,
    volatileOrganic: 0.25 / 1000,
    nitrousOxide: 0.33 / 1000,
    sulphurOxide: 0.01 / 1000,
    particulateMatter: 0.01 / 1000,
  },
  euro5: {
    carbonMonoxide: 3.17 / 1000,
    volatileOrganic: 0.17 / 1000,
    nitrousOxide: 0.21 / 1000,
    sulphurOxide: 0.007 / 1000,
    particulateMatter: 0.01 / 1000,
  },
  euro6: {
    carbonMonoxide: 3.08 / 1000,
    volatileOrganic: 0.17 / 1000,
    nitrousOxide: 0.19 / 1000,
    sulphurOxide: 0.006 / 1000,
    particulateMatter: 0.01 / 1000,
  },
})

export const PassengerSUVDiesel = Object.freeze({
  preEuro: {
    carbonMonoxide: 2.24 / 1000,
    volatileOrganic: 0.57 / 1000,
    nitrousOxide: 3.89 / 1000,
    sulphurOxide: 0.06 / 1000,
    particulateMatter: 0.47 / 1000,
  },
  euro1: {
    carbonMonoxide: 2.58 / 1000,
    volatileOrganic: 0.63 / 1000,
    nitrousOxide: 3.53 / 1000,
    sulphurOxide: 0.06 / 1000,
    particulateMatter: 0.27 / 1000,
  },
  euro2: {
    carbonMonoxide: 2.32 / 1000,
    volatileOrganic: 0.54 / 1000,
    nitrousOxide: 3.45 / 1000,
    sulphurOxide: 0.05 / 1000,
    particulateMatter: 0.39 / 1000,
  },
  euro3: {
    carbonMonoxide: 1.85 / 1000,
    volatileOrganic: 0.43 / 1000,
    nitrousOxide: 2.68 / 1000,
    sulphurOxide: 0.04 / 1000,
    particulateMatter: 0.17 / 1000,
  },
  euro4: {
    carbonMonoxide: 1.43 / 1000,
    volatileOrganic: 0.34 / 1000,
    nitrousOxide: 3.17 / 1000,
    sulphurOxide: 0.02 / 1000,
    particulateMatter: 0.14 / 1000,
  },
  euro5: {
    carbonMonoxide: 0.29 / 1000,
    volatileOrganic: 0.04 / 1000,
    nitrousOxide: 1.2 / 1000,
    sulphurOxide: 0.006 / 1000,
    particulateMatter: 0.01 / 1000,
  },
  euro6: {
    carbonMonoxide: 0.83 / 1000,
    volatileOrganic: 0.05 / 1000,
    nitrousOxide: 0.59 / 1000,
    sulphurOxide: 0.005 / 1000,
    particulateMatter: 0.01 / 1000,
  },
})

export const LightDutyGasoline = Object.freeze({
  preEuro: {
    carbonMonoxide: 19.36 / 1000,
    volatileOrganic: 1.71 / 1000,
    nitrousOxide: 2.66 / 1000,
    sulphurOxide: 0.04 / 1000,
    particulateMatter: 0.04 / 1000,
  },
  euro1: {
    carbonMonoxide: 19.76 / 1000,
    volatileOrganic: 1.72 / 1000,
    nitrousOxide: 2.8 / 1000,
    sulphurOxide: 0.02 / 1000,
    particulateMatter: 0.02 / 1000,
  },
  euro2: {
    carbonMonoxide: 9.99 / 1000,
    volatileOrganic: 1.06 / 1000,
    nitrousOxide: 1.55 / 1000,
    sulphurOxide: 0.02 / 1000,
    particulateMatter: 0.01 / 1000,
  },
  euro3: {
    carbonMonoxide: 7.89 / 1000,
    volatileOrganic: 0.81 / 1000,
    nitrousOxide: 1.24 / 1000,
    sulphurOxide: 0.02 / 1000,
    particulateMatter: 0.01 / 1000,
  },
  euro4: {
    carbonMonoxide: 5.31 / 1000,
    volatileOrganic: 0.28 / 1000,
    nitrousOxide: 0.42 / 1000,
    sulphurOxide: 0.012 / 1000,
    particulateMatter: 0.014 / 1000,
  },
  euro5: {
    carbonMonoxide: 3.96 / 1000,
    volatileOrganic: 0.19 / 1000,
    nitrousOxide: 0.27 / 1000,
    sulphurOxide: 0.007 / 1000,
    particulateMatter: 0.013 / 1000,
  },
  euro6: {
    carbonMonoxide: 3.85 / 1000,
    volatileOrganic: 0.19 / 1000,
    nitrousOxide: 0.27 / 1000,
    sulphurOxide: 0.006 / 1000,
    particulateMatter: 0.013 / 1000,
  },
})

export const LightDutyDiesel = Object.freeze({
  preEuro: {
    carbonMonoxide: 2.55 / 1000,
    volatileOrganic: 0.64 / 1000,
    nitrousOxide: 4.59 / 1000,
    sulphurOxide: 0.06 / 1000,
    particulateMatter: 0.49 / 1000,
  },
  euro1: {
    carbonMonoxide: 2.66 / 1000,
    volatileOrganic: 0.66 / 1000,
    nitrousOxide: 3.64 / 1000,
    sulphurOxide: 0.06 / 1000,
    particulateMatter: 0.3 / 1000,
  },
  euro2: {
    carbonMonoxide: 2.47 / 1000,
    volatileOrganic: 0.59 / 1000,
    nitrousOxide: 3.67 / 1000,
    sulphurOxide: 0.05 / 1000,
    particulateMatter: 0.42 / 1000,
  },
  euro3: {
    carbonMonoxide: 2.07 / 1000,
    volatileOrganic: 0.5 / 1000,
    nitrousOxide: 3.0 / 1000,
    sulphurOxide: 0.04 / 1000,
    particulateMatter: 0.18 / 1000,
  },
  euro4: {
    carbonMonoxide: 1.45 / 1000,
    volatileOrganic: 0.35 / 1000,
    nitrousOxide: 3.15 / 1000,
    sulphurOxide: 0.02 / 1000,
    particulateMatter: 0.14 / 1000,
  },
  euro5: {
    carbonMonoxide: 0.3 / 1000,
    volatileOrganic: 0.04 / 1000,
    nitrousOxide: 1.23 / 1000,
    sulphurOxide: 0.006 / 1000,
    particulateMatter: 0.01 / 1000,
  },
  euro6: {
    carbonMonoxide: 0.78 / 1000,
    volatileOrganic: 0.05 / 1000,
    nitrousOxide: 0.61 / 1000,
    sulphurOxide: 0.005 / 1000,
    particulateMatter: 0.01 / 1000,
  },
})

export const MediumDutyDiesel = Object.freeze({
  preEuro: {
    carbonMonoxide: 2.91 / 1000,
    volatileOrganic: 0.75 / 1000,
    nitrousOxide: 9.35 / 1000,
    sulphurOxide: 0.12 / 1000,
    particulateMatter: 0.6 / 1000,
  },
  euro1: {
    carbonMonoxide: 3.12 / 1000,
    volatileOrganic: 0.79 / 1000,
    nitrousOxide: 8.76 / 1000,
    sulphurOxide: 0.11 / 1000,
    particulateMatter: 0.43 / 1000,
  },
  euro2: {
    carbonMonoxide: 2.71 / 1000,
    volatileOrganic: 0.76 / 1000,
    nitrousOxide: 8.74 / 1000,
    sulphurOxide: 0.11 / 1000,
    particulateMatter: 0.56 / 1000,
  },
  euro3: {
    carbonMonoxide: 2.2 / 1000,
    volatileOrganic: 0.62 / 1000,
    nitrousOxide: 4.86 / 1000,
    sulphurOxide: 0.09 / 1000,
    particulateMatter: 0.26 / 1000,
  },
  euro4: {
    carbonMonoxide: 1.94 / 1000,
    volatileOrganic: 0.47 / 1000,
    nitrousOxide: 3.89 / 1000,
    sulphurOxide: 0.03 / 1000,
    particulateMatter: 0.24 / 1000,
  },
  euro5: {
    carbonMonoxide: 0.75 / 1000,
    volatileOrganic: 0.06 / 1000,
    nitrousOxide: 1.97 / 1000,
    sulphurOxide: 0.01 / 1000,
    particulateMatter: 0.01 / 1000,
  },
  euro6: {
    carbonMonoxide: 0.63 / 1000,
    volatileOrganic: 0.05 / 1000,
    nitrousOxide: 0.58 / 1000,
    sulphurOxide: 0.007 / 1000,
    particulateMatter: 0.014 / 1000,
  },
})

export const HeavyDutyDiesel = Object.freeze({
  preEuro: {
    carbonMonoxide: 3.4 / 1000,
    volatileOrganic: 1.34 / 1000,
    nitrousOxide: 21.92 / 1000,
    sulphurOxide: 0.29 / 1000,
    particulateMatter: 0.8 / 1000,
  },
  euro1: {
    carbonMonoxide: 4.86 / 1000,
    volatileOrganic: 1.12 / 1000,
    nitrousOxide: 21.77 / 1000,
    sulphurOxide: 0.23 / 1000,
    particulateMatter: 0.98 / 1000,
  },
  euro2: {
    carbonMonoxide: 4.51 / 1000,
    volatileOrganic: 1.07 / 1000,
    nitrousOxide: 21.76 / 1000,
    sulphurOxide: 0.22 / 1000,
    particulateMatter: 0.95 / 1000,
  },
  euro3: {
    carbonMonoxide: 4.05 / 1000,
    volatileOrganic: 0.99 / 1000,
    nitrousOxide: 15.41 / 1000,
    sulphurOxide: 0.19 / 1000,
    particulateMatter: 0.56 / 1000,
  },
  euro4: {
    carbonMonoxide: 2.25 / 1000,
    volatileOrganic: 0.88 / 1000,
    nitrousOxide: 8.68 / 1000,
    sulphurOxide: 0.06 / 1000,
    particulateMatter: 0.52 / 1000,
  },
  euro5: {
    carbonMonoxide: 1.12 / 1000,
    volatileOrganic: 0.26 / 1000,
    nitrousOxide: 5.18 / 1000,
    sulphurOxide: 0.01 / 1000,
    particulateMatter: 0.02 / 1000,
  },
  euro6: {
    carbonMonoxide: 1.03 / 1000,
    volatileOrganic: 0.25 / 1000,
    nitrousOxide: 2.85 / 1000,
    sulphurOxide: 0.015 / 1000,
    particulateMatter: 0.02 / 1000,
  },
})

export const Motorcycles = Object.freeze({
  twoStroke: {
    carbonMonoxide: 27.5 / 1000,
    volatileOrganic: 14.4 / 1000,
    nitrousOxide: 0.16 / 1000,
    sulphurOxide: 0.01 / 1000,
    particulateMatter: 0.35 / 1000,
  },
  fourStroke: {
    carbonMonoxide: 16.0 / 1000,
    volatileOrganic: 5.0 / 1000,
    nitrousOxide: 0.99 / 1000,
    sulphurOxide: 0.02 / 1000,
    particulateMatter: 0.21 / 1000,
  },
})

export const Generators = Object.freeze({
  carbonMonoxide: 14 / 1000,
  volatileOrganic: 1.6 / 1000,
  nitrousOxide: 24 / 1000,
  sulphurOxide: 0.03 / 1000,
  particulateMatter: 0.8 / 1000,
})

export const EvFossilFuel = Object.freeze({
  EV0: {
    hev: {
      carbonMonoxide: 0.001 / 1000,
      volatileOrganic: 0.31 / 1000,
      nitrousOxide: 0.2 / 1000,
      sulphurOxide: 0.37 / 1000,
      particulateMatter: 0.24 / 1000,
      carbonDioxide: 0.41 / 1000,
    },
    phev: {
      carbonMonoxide: 0.75 / 1000,
      volatileOrganic: 0.84 / 1000,
      nitrousOxide: 0.8 / 1000,
      sulphurOxide: 0.84 / 1000,
      particulateMatter: 0.65 / 1000,
      carbonDioxide: 0.9 / 1000,
    },
    bev: {
      carbonMonoxide: 1 / 1000,
      volatileOrganic: 1 / 1000,
      nitrousOxide: 1 / 1000,
      sulphurOxide: 1 / 1000,
      particulateMatter: 1 / 1000,
      carbonDioxide: 1 / 1000,
    },
  },
  EV20: {
    hev: {},
    phev: {},
    bev: {},
  },
  EV40: {
    hev: {
      carbonMonoxide: 0.001 / 1000,
      volatileOrganic: 0.31 / 1000,
      nitrousOxide: 0.2 / 1000,
      sulphurOxide: 0.37 / 1000,
      particulateMatter: 0.24 / 1000,
      carbonDioxide: 0.41 / 1000,
    },
    phev: {
      carbonMonoxide: 0.75 / 1000,
      volatileOrganic: 0.82 / 1000,
      nitrousOxide: 0.61 / 1000,
      sulphurOxide: -0.91 / 1000,
      particulateMatter: 0.43 / 1000,
      carbonDioxide: 0.51 / 1000,
    },
    bev: {
      carbonMonoxide: 0.99 / 1000,
      volatileOrganic: 0.99 / 1000,
      nitrousOxide: 0.61 / 1000,
      sulphurOxide: -0.91 / 1000,
      particulateMatter: 0.43 / 1000,
      carbonDioxide: 0.69 / 1000,
    },
  },
  EV60: {
    hev: {
      carbonMonoxide: 0.001 / 1000,
      volatileOrganic: 0.31 / 1000,
      nitrousOxide: 0.2 / 1000,
      sulphurOxide: 0.37 / 1000,
      particulateMatter: 0.24 / 1000,
      carbonDioxide: 0.41 / 1000,
    },
    phev: {
      carbonMonoxide: 0.75 / 1000,
      volatileOrganic: 0.82 / 1000,
      nitrousOxide: 0.52 / 1000,
      sulphurOxide: -1.65 / 1000,
      particulateMatter: 0.34 / 1000,
      carbonDioxide: 0.34 / 1000,
    },
    bev: {
      carbonMonoxide: 0.99 / 1000,
      volatileOrganic: 0.99 / 1000,
      nitrousOxide: 0.52 / 1000,
      sulphurOxide: -1.65 / 1000,
      particulateMatter: 0.34 / 1000,
      carbonDioxide: 0.55 / 1000,
    },
  },
  EV80: {
    hev: {
      carbonMonoxide: 0.001 / 1000,
      volatileOrganic: 0.31 / 1000,
      nitrousOxide: 0.2 / 1000,
      sulphurOxide: 0.37 / 1000,
      particulateMatter: 0.24 / 1000,
      carbonDioxide: 0.41 / 1000,
    },
    phev: {
      carbonMonoxide: 0.75 / 1000,
      volatileOrganic: 0.82 / 1000,
      nitrousOxide: 0.43 / 1000,
      sulphurOxide: -2.48 / 1000,
      particulateMatter: 0.24 / 1000,
      carbonDioxide: 0.15 / 1000,
    },
    bev: {
      carbonMonoxide: 0.99 / 1000,
      volatileOrganic: 0.99 / 1000,
      nitrousOxide: 0.43 / 1000,
      sulphurOxide: -2.48 / 1000,
      particulateMatter: 0.24 / 1000,
      carbonDioxide: 0.2 / 1000,
    },
  },
  EV100: {
    hev: {
      carbonMonoxide: 0.001 / 1000,
      volatileOrganic: 0.31 / 1000,
      nitrousOxide: 0.2 / 1000,
      sulphurOxide: 0.37 / 1000,
      particulateMatter: 0.24 / 1000,
      carbonDioxide: 0.41 / 1000,
    },
    phev: {
      carbonMonoxide: 0.75 / 1000,
      volatileOrganic: 0.82 / 1000,
      nitrousOxide: 0.33 / 1000,
      sulphurOxide: -3.32 / 1000,
      particulateMatter: 0.14 / 1000,
      carbonDioxide: -0.03 / 1000,
    },
    bev: {
      carbonMonoxide: 0.99 / 1000,
      volatileOrganic: 0.99 / 1000,
      nitrousOxide: 0.33 / 1000,
      sulphurOxide: -3.32 / 1000,
      particulateMatter: 0.14 / 1000,
      carbonDioxide: 0.1 / 1000,
    },
  },
})

// from prev tool: preEuroValue = this.emissionFactors.passengerCarsGasoline.euro4
export const preEuroValue = PassengerCarsGasoline.euro4
